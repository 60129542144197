.MobileFooter-module__upperSection {
  background-color: #f4f6f9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 13px;
  border-radius: 4px;
  border: 1px solid #f4f6f9;
  margin-top: 32px;
}

.MobileFooter-module__upperSection > p {
  margin-top: 8px;
  margin-bottom: 24px;
  color: #484848;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}

.MobileFooter-module__lowerSection {
  margin-top: 56px;
  padding-bottom: 20px;
}

.MobileFooter-module__lowerSection > p {
  color: #697a8f;
  font-size: 12px;
  line-height: 23px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.MobileFooter-module__paymentMethods > span {
  color: #00b362;
  font-size: 12px;
  line-height: 16px;
  margin: 0 5px;
}

.MobileFooter-module__paymentMethods > ul {
  display: flex;
  margin-top: 12px;
  flex-wrap: wrap;
  width: 213px;
}

.MobileFooter-module__links,
.MobileFooter-module__links > a {
  font-size: 12px;
  line-height: 23px;
  color: #697a8f;
}

@media (max-width: 1024px) {
  body[dir="ltr"] .MobileFooter-module__lowerSection {
    border-top: solid 1px #f4f6f9;
    padding-top: 32px;
    margin-top: 32px;
  }

  body[dir="ltr"] .MobileFooter-module__lowerSection > p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}


/* vatIcon */
.MobileFooter-module__footerWithVat {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 265px;
}

.MobileFooter-module__footerWithVat > img {
  max-width: 200px;
  width: 100%;
}

.MobileFooter-module__footerWithVat > .MobileFooter-module__vatLink {
  display: inline-flex;
  align-items: center;
}

a.MobileFooter-module__vatLink:after {
  content: "";
  width: 2px;
  height: 32px;
  background-color: #b2bcc2;
  display: inline-block;
  margin: 0 15px;
  position: relative;
  top: -2px;
}
